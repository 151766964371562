<template>
  <b-card>
    <b-row>
      <!-- text and button -->
      <b-col
        sm="6"
        class="d-flex justify-content-between flex-column mt-1 mt-sm-0"
      >
        <b-card-text class="font-weight-bold text-primary">
          {{ domain.name }}
        </b-card-text>
      </b-col>

      <b-col sm="6">
        <b-card-text class="text-right">
          <a
            :href="urlDomain"
            target="_blank"
            class="font-weight-bold text-muted"
          >
            estelarbet{{ domain.domain }}
            <feather-icon
              icon="ExternalLinkIcon"
              size="14"
              class="text-muted ml-25"
            />
          </a>
        </b-card-text>
      </b-col>
    </b-row>

    <hr />

    <!-- progress bar -->
    <b-row class="pt-50">
      <b-col cols="6" class="mb-2">
        <b-card-text class="mb-50"> Hoy </b-card-text>

        <analytics-progress-bar
          :active="domain.today.percentage"
          :inactive="domain.today.percentageInactive"
        />

        <b-card-text class="d-flex justify-content-between small mb-50">
          <span>{{ formatNumber(domain.today.active) }} Activo</span>
          <span>{{ formatNumber(domain.today.inactive) }} Inactivo</span>
        </b-card-text>
      </b-col>
      <b-col cols="6" class="mb-2">
        <b-card-text class="mb-50"> Semana actual </b-card-text>

        <analytics-progress-bar
          :active="domain.week.percentage"
          :inactive="domain.week.percentageInactive"
          variant="success"
        />

        <b-card-text class="d-flex justify-content-between small mb-50">
          <span>{{ formatNumber(domain.week.active) }} Activo</span>
          <span>{{ formatNumber(domain.week.inactive) }} Inactivo</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <b-card-text class="mb-50"> Mes actual </b-card-text>

        <analytics-progress-bar
          :active="domain.month.percentage"
          :inactive="domain.month.percentageInactive"
          variant="info"
        />

        <b-card-text class="d-flex justify-content-between small mb-50">
          <span>{{ formatNumber(domain.month.active) }} Activo</span>
          <span>{{ formatNumber(domain.month.inactive) }} Inactivo</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <b-card-text class="mb-50"> Año actual </b-card-text>

        <analytics-progress-bar
          :active="domain.year.percentage"
          :inactive="domain.year.percentageInactive"
          variant="warning"
        />

        <b-card-text class="d-flex justify-content-between small mb-50">
          <span>{{ formatNumber(domain.year.active) }} Activo</span>
          <span>{{ formatNumber(domain.year.inactive) }} Inactivo</span>
        </b-card-text>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AnalyticsProgressBar from './AnalyticsProgressBar.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    AnalyticsProgressBar,
  },
  directives: {
    Ripple,
  },
  props: {
    domain: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    urlDomain() {
      return `https://www.estelarbet${this.domain.domain}`
    },
  },
  setup() {
    function formatNumber(hours) {
      const format = new Intl.NumberFormat('es-CL')

      return `${format.format(hours)}h`
    }

    return {
      formatNumber,
    }
  },
}
</script>
