<template>
  <b-card no-body>
    <b-card-body class="d-flex align-items-center justify-content-between py-1">
      <div class="truncate">
        <h4 class="mb-25 font-weight-bolder">
          {{ name }}
        </h4>
        <a
          :href="urlDomain"
          target="_blank"
          class="text-body"
        >
          {{ textDomain }}
          <feather-icon
            icon="ExternalLinkIcon"
            size="14"
            class="text-primary ml-25"
          />
        </a>
      </div>
      <b-avatar
        :variant="color"
        size="40"
      >
        <feather-icon
          :icon="icon"
          :class="status ? 'pulse-success' : 'pulse-danger'"
          size="21"
        />
      </b-avatar>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
  },
  computed: {
    urlDomain() {
      return `https://www.estelarbet${this.domain}`
    },
    textDomain() {
      return `estelarbet${this.domain}`
    },
    icon() {
      return this.status ? 'SmileIcon' : 'AlertOctagonIcon'
    },
    color() {
      return this.status ? 'light-success' : 'light-danger'
    },
  },
}
</script>

<style lang="scss">
.pulse-success {
  border-radius: 50%;
  animation: pulse-animation-success 1s infinite;
}
.pulse-danger {
  border-radius: 50%;
  animation: pulse-animation-danger 1s infinite;
}

@keyframes pulse-animation-success {
  0% {
    box-shadow: 0 0 0 0px rgba(40, 199, 111, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(40, 199, 111, 0);
  }
}
@keyframes pulse-animation-danger {
  0% {
    box-shadow: 0 0 0 0px rgba(234, 84, 85, 0.4);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(234, 84, 85, 0);
  }
}
</style>
