import http from './http'

const path = 'admin/analytics'

class Analytics {
  constructor(_http) {
    this.http = _http
  }

  statuses(opt = {}) {
    return this.http.get(`api/${path}/statuses`, opt)
  }

  statistics(opt = {}) {
    return this.http.get(`api/${path}/statistics`, opt)
  }
}

const AnalyticsRequest = new Analytics(http)

export default AnalyticsRequest
