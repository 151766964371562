<template>
  <b-progress
    max="100"
    show-value
    v-b-tooltip.hover.html
    :title="`<div class='text-right'><strong>${active}% Activo</strong><br><strong>${inactive}% Inactivo</strong></div>`"
  >
    <b-progress-bar
      :value="active"
      :label="`${active}%`"
      :variant="variant"
      animated
    />
    <b-progress-bar
      :value="inactive"
      :label="`${inactive}%`"
      variant="danger"
    />
  </b-progress>
</template>

<script>
import {
  BProgress,
  BProgressBar,
  VBTooltip,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  components: {
    BProgress,
    BProgressBar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    active: {
      type: [String, Number],
      default: 0,
      require: true,
    },
    inactive: {
      type: [String, Number],
      default: 0,
      require: true,
    },
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      target: null,
    }
  },
  created() {
    this.target = uuidv4()
  },
}
</script>

<style lang="scss">
.progress {
  height: 8px !important;
  font-size: 0.5rem !important;
}
</style>
