<template>
  <section id="dashboard-analytics">
    <analytics-statuses />

    <analytics-progress-section />
  </section>
</template>

<script>
import AnalyticsStatuses from './components/AnalyticsStatuses.vue'
import AnalyticsProgressSection from './components/AnalyticsProgressSection.vue'

export default {
  components: {
    AnalyticsStatuses,
    AnalyticsProgressSection,
  },
}
</script>
