<template>
  <div>
    <b-row v-if="loading">
      <b-col
        v-for="index in 5"
        :key="index"
        md="2"
        lg="2"
      >
        <b-card no-body>
          <b-card-body class="d-flex align-items-center justify-content-between py-1">
            <div class="truncate d-flex flex-grow-1 flex-column">
              <b-skeleton animation="wave" width="50%" height="20px" />
              <b-skeleton animation="wave" width="80%" class="mb-0" />
            </div>
            <b-skeleton type="avatar" size="30" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col
        v-for="(domain, index) in domains"
        :key="index"
        md="2"
        lg="2"
      >
        <analytics-status
          :name="domain.name"
          :domain="domain.domain"
          :status="domain.status"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSkeleton,
  BCard,
  BCardBody,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import AnalyticsRequest from '@/@api/analytics'
import AnalyticsStatus from './AnalyticsStatus.vue'

export default {
  components: {
    BRow,
    BCol,
    BSkeleton,
    BCard,
    BCardBody,
    AnalyticsStatus,
  },
  setup() {
    const loading = ref(false)
    const domains = ref([])

    async function initialize() {
      loading.value = true
      try {
        const { data: { data } } = await AnalyticsRequest.statuses()

        domains.value = data
        loading.value = false
      } catch (error) {
        console.log(error)
      } finally {
        // loading.value = false
      }
    }

    onMounted(() => {
      initialize()
    })

    return {
      loading,
      domains,
    }
  },
}
</script>
