<template>
  <div>
    <b-row v-if="loading">
      <b-col
        v-for="index in 4"
        :key="index"
        cols="12"
        md="6"
      >
        <b-card>
          <div class="w-100 d-flex justify-content-between">
            <b-skeleton animation="wave" width="230px" height="20px" class="mb-0" />
            <b-skeleton animation="wave" width="60px" height="20px" class="mb-0" style="opacity: 0.2" />
          </div>

          <hr />

          <b-row class="avg-sessions pt-50">
            <b-col cols="6" class="mb-2">
              <b-skeleton animation="wave" width="70px" height="15px" class="mb-50" />
              <b-skeleton animation="wave" width="100%" height="6px" class="mb-50" />
              <b-skeleton animation="wave" width="40%" height="10px" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col v-for="(domain, key) in domains" :key="key" cols="12" md="6">
        <analytics-progress-card :domain="domain" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSkeleton,
  BCard,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import AnalyticsRequest from '@/@api/analytics'
import AnalyticsProgressCard from './AnalyticsProgressCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BSkeleton,
    BCard,
    AnalyticsProgressCard,
  },
  setup() {
    const loading = ref(false)
    const domains = ref([])

    async function initialize() {
      loading.value = true
      try {
        const { data: { data } } = await AnalyticsRequest.statistics()

        domains.value = data
        loading.value = false
      } catch (error) {
        console.log(error)
      } finally {
        // loading.value = false
      }
    }

    onMounted(() => {
      initialize()
    })

    return {
      loading,
      domains,
    }
  },
}
</script>
